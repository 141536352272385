import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { useCurrentUser } from '@/auth/useCurrentUser';

import LoadingSpinner from '@/components/feedback/LoadingSpinner';
import { PatientDashboard } from '@/components/patient/PatientDashboard';

const PatientHome = () => {
  const { loading, isLoggedIn } = useCurrentUser();

  const router = useRouter();

  useEffect(() => {
    if (!loading && !isLoggedIn) {
      router.push('/login');
    }
  }, [loading, isLoggedIn, router]);

  if (loading) return <LoadingSpinner />;

  return <PatientDashboard />;
};

export default PatientHome;
