import moment from 'moment';
import { useState } from 'react';

import { Table } from '@/components/data-display/Table';
import Text from '@/components/data-display/Text';
import UserAvatar from '@/components/data-display/UserAvatar';

import FullButton from '@/components/data-entry/FullButton';

import LoadingSpinner from '@/components/feedback/LoadingSpinner';
import { NoResultsMessage } from '@/components/feedback/NoResultsMessage';

import { usePatientDashboardQuery } from '@/generated/graphql';

import { patientApptRoomHref } from '@/lib/constants';

import { handleError } from '@/utils/errors';
import { throttledGetTimeXHoursAgo } from '@/utils/time';

import { wrapWithErrorBoundary } from '../feedback/utils/wrapWithErrorBoundary';

const takeItems = 16;

export const PatientDashboardUpcoming = () => {
  const [oneHourAgoISO] = useState(throttledGetTimeXHoursAgo(1));

  const { data, loading, fetchMore } = usePatientDashboardQuery({
    notifyOnNetworkStatusChange: true,
    variables: { startsAt: { gt: oneHourAgoISO }, take: takeItems },
  });

  const upcomingBookings = data?.authenticatedItem?.patient?.bookings || [];

  const onLoadMore = async cancelObserving => {
    const fetchMoreResponse = await fetchMore({
      variables: {
        skip: upcomingBookings.length,
        startsAt: { gt: oneHourAgoISO },
        take: takeItems,
      },
    });

    if (
      fetchMoreResponse?.data?.authenticatedItem?.patient?.bookings?.length ===
      0
    ) {
      cancelObserving();
    }
  };
  return (
    <>
      {!upcomingBookings?.length && !loading && (
        <NoResultsMessage message="You have no more appointments." />
      )}

      {loading && upcomingBookings?.length <= 0 && <LoadingSpinner />}

      {upcomingBookings?.length > 0 && (
        <div className="p-4 h-full">
          <Table onReachEnd={onLoadMore}>
            <Table.Head>
              <Table.Row>
                <Table.HeadCell>Doctor</Table.HeadCell>
                <Table.HeadCell>Type</Table.HeadCell>
                <Table.HeadCell>Time</Table.HeadCell>
                <Table.HeadCell className="pr-5 sm:pr-7 text-right">
                  Action
                </Table.HeadCell>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {upcomingBookings.map(booking => {
                const doctorUser = booking?.doctor?.user;
                const apptId = booking?.appointment?.id;

                const doctorFirstName = doctorUser?.firstName;
                const doctorLastName = doctorUser?.lastName;

                const { id: bookingID, startsAt, event } = booking;

                const eventType = event?.eventType?.label;
                const eventTypeValue = event?.eventType?.value;

                const startsAtTime = moment(startsAt).format('h:mm a');

                if (!apptId) {
                  handleError(
                    `Appointment has no doctor, [appt.id]: ${apptId}`
                  );
                  return null;
                }
                if (!doctorUser) {
                  handleError(
                    `Appointment has no doctor, [appt.id]: ${apptId}`
                  );
                  return null;
                }
                if (!bookingID) {
                  handleError(
                    `Appointment has no booking, [appt.id]: ${apptId}`
                  );
                  return null;
                }

                return (
                  <Table.Row key={apptId}>
                    <Table.Cell className="font-medium">
                      {!doctorUser && 'Unknown Doctor'}
                      {doctorUser && (
                        <div className="flex flex-row gap-2 items-center">
                          <UserAvatar size="30px" user={doctorUser} />
                          <Text
                            className="ellipsis"
                            title={`${doctorFirstName} ${doctorLastName}`}
                          >
                            {doctorFirstName}
                          </Text>
                        </div>
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      <Text className="ellipsis" title={eventType as string}>
                        {eventType}
                      </Text>
                    </Table.Cell>
                    <Table.Cell>
                      <Text className="ellipsis" title={startsAtTime}>
                        {startsAtTime}
                      </Text>
                    </Table.Cell>
                    <Table.Cell className="pr-4 text-right font-medium sm:pr-6 min-w-[200px] max-w-[220px]">
                      {eventTypeValue === 'virtual' && (
                        <FullButton
                          data-testid={`btn-join-${eventType}-appt`}
                          size="sm"
                          variant="outline"
                          href={`${patientApptRoomHref}/${apptId}`}
                        >
                          Join
                        </FullButton>
                      )}
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </div>
      )}
    </>
  );
};

export const PatientDashboardUpcomingWithBoundary = wrapWithErrorBoundary(
  PatientDashboardUpcoming
);
