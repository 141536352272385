import { useCurrentUser } from '@/auth/useCurrentUser';

import { DateTimeCardinal } from '@/components/common/DateTimeCardinal';

import Cardinal from '@/components/data-display/Cardinal';
import Text from '@/components/data-display/Text';
import UserAvatar from '@/components/data-display/UserAvatar';

import FullButton from '@/components/data-entry/FullButton';

import FancyCard from '@/components/surfaces/FancyCard';

import DashboardBodyLayout from '@/layouts/DashboardBodyLayout';

import { isFeedbackModalOpenVar } from '@/shared-state/common/feedback';

import { PatientDashboardStatsWithBoundary } from './PatientDashboardStats';
import { PatientDashboardUpcomingWithBoundary } from './PatientDashboardUpcoming';

export const PatientDashboard = () => {
  const currentUser = useCurrentUser();

  return (
    <DashboardBodyLayout
      title={
        <div className="flex flex-wrap justify-between items-start gap-3 mt-1">
          <Text block variant="h2" className="font-serif">
            Dashboard
          </Text>

          <FullButton
            className="md:hidden"
            onClick={() => isFeedbackModalOpenVar(true)}
            // size="lg"
            variant="outline"
          >
            Contact Us
          </FullButton>
        </div>
      }
      extra={
        <>
          <FullButton
            className="hidden md:block w-[200px] self-end"
            onClick={() => isFeedbackModalOpenVar(true)}
            size="lg"
            variant="outline"
          >
            Contact Us
          </FullButton>

          <div className="basis-48 rounded-lg border to-[#2d494e] from-[#38585e] bg-gradient-radial relative bg-left-top p-3">
            <div className="z-10 h-full flex flex-col justify-center items-center p-3 relative">
              <div>
                <Text
                  block
                  className="text-white text-center font-serif"
                  variant="h1"
                >
                  Welcome to PocketMD
                </Text>
              </div>
              <div
                className="bg-right-top bg-no-repeat absolute bottom-0 right-0 w-full h-full z-0 bg-contain"
                style={{
                  backgroundImage:
                    'url("/images/bg-triangles-right-bottom.svg")',
                }}
              />
            </div>

            {/* <div
              className="bg-right-top bg-no-repeat absolute bottom-0 right-0 w-full h-full z-0 bg-contain"
              style={{
                backgroundImage: 'url("/images/bg-triangles-right-bottom.svg")',
              }}
            /> */}
          </div>

          {/* Stats */}
          <PatientDashboardStatsWithBoundary />
        </>
      }
      showExtraOnMobile
    >
      <div className="bg-surface border rounded-lg p-4">
        <div className="items-center flex gap-4 pb-4">
          <div className="pb-2">
            <UserAvatar size="70px" user={currentUser} />
          </div>

          <div className="pl-2">
            <Text className="uppercase">Welcome</Text>

            <Text as="h3" className="text-2xl">
              {currentUser?.firstName} {currentUser?.lastName}
            </Text>
          </div>
        </div>

        <div className="flex gap-4 justify-between">
          <div className="flex-1 md:basis-[190px]">
            <DateTimeCardinal headClassName="text-sm pb-2" />
          </div>

          <div className="flex-[4] basis-1/2">
            <Text>
              This is your Dashboard. You can manage all of your healthcare
              details here!
            </Text>
          </div>
        </div>
      </div>

      <FancyCard
        icon="calendar-days"
        title="Upcoming Appointments"
        className="hidden md:flex flex-1"
      >
        <PatientDashboardUpcomingWithBoundary />
      </FancyCard>
    </DashboardBodyLayout>
  );
};
